import { $channelId, $isChannelLoaded } from '@channel/channel.model'
import { invoke } from '@withease/factories'
import { createInviteLinksWidgetModel } from '../../../widgets/analytics_filters/invite_links_filter/factory'

export const unloadingReportsFiltersModel = invoke(() =>
  createInviteLinksWidgetModel({
    channelId: $channelId,
    isEnabled: $isChannelLoaded,
  }),
)
